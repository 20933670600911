import $ from 'jquery'
import {useState, useEffect} from 'react'

//Components
import TextInfoEmpreendimento from '../TextInfoEmpreendimento';

//Styles
import './style.css'

//Imagens
import btnLeftImg from './img/btnLeft.png'
import btnRightImg from './img/btnRight.png'


function CarrosselInfo({ empreendimento }){ 

    //Alteração do layout do carrossel de acordo com o tamanho da tela
    //const[widowsWidth, setwidowsWidth] = useState(window.innerWidth)

    const infoCarrossel = (empe, tipo) => {
        if(tipo=== 1){
            return `<p>• Localizado no Coração da ilha (Entroncamento de Mar Grande)</p>
                    <p>• Próximo aos terminais marítimos</p>
                    <p>• Próximos a posto de combustível e conveniência</p>
                    <p>• Próximo aos mercados Atacadão e Atakarejo</p>
                    <p>• Próximo a BA-001</p>`      
      
        }else if(tipo === 2){
            if(empe === 'bosque'){
                return `<p>• Rede elétrica</p>
                    <p>• Rede de água</p>
                    <p>• Rede de escoamento hídrico</p>
                    <p>• Iluminação moderna em todo empreendimento</p>
                    <p>• Cascalho em todas as vias</p>
                    <p>• Meio fio em todas as vias</p>
                    <p>• Portaria com guarita</p>
                    <p>• Área de Lazer (Quadra poliesportiva, ciclovia, quiosque e área verde planejada)</p>`
      
            }else{
                return `<p>• Rede elétrica<p/>
                    <p>• Rede de água<p/>
                    <p>• Rede de escoamento hídrico<p/>
                    <p>• Iluminação moderna em todo empreendimento<p/>
                    <p>• Cascalho em todas as vias<p/>
                    <p>• Meio fio em todas as vias<p/>
                    <p>• Portaria com guarita</p>`
            }
      
        }else if(tipo === 3){
            return `<p>• Localização estratégica<p/>
                    <p>• Empreendimento 100% regularizado<p/>
                    <p>• Pavimentação até a entrada do empreendimento<p/>
                    <p>• Condições de pagamento que apenas a UEI proporciona</p>`
        }
    }


    //Incializar Carrossel de Informações
    const pagePassInfoText = (position) => {        
        let textInfo = $(".text-empreendimento")
        let pags = $(".btn-page-info")        

        let index = position

        if(position > 0 && position <= 3){
            if (position > textInfo.length){
                index = 1
            }
    
            if (position < 1){
                index = textInfo.length
            }
            
            for (let i = 0; i < textInfo.length; i++) {
                textInfo.eq(i).css('display', 'none'); 
            }
    
            for (let i = 0; i < pags.length; i++) {
                pags.eq(i).removeClass("activePageTextInfo");
            }
    
            textInfo.eq(index-1).fadeIn(1000);    
    
            pags.eq(index-1).addClass("activePageTextInfo"); 
            setIndexActiveInfoText(index-1)
        }

              
        
    }

    const pagePass = (typeText) => {
        let indexPagAtual = $('.btn-page-info.activePageTextInfo').index() + 1

        if(typeText === 'N'){
            indexPagAtual += 1
        }else{
            indexPagAtual -= 1
        }
     
        pagePassInfoText(indexPagAtual)
    }

    
    
    const [localizacao, setLocalizacao] = useState(infoCarrossel(empreendimento, 1))
    const [infraestrutura, setInfraestrutura] = useState(infoCarrossel(empreendimento, 2))
    const [beneficios, setBeneficios] = useState(infoCarrossel(empreendimento, 3))

    const [indexActiveInfoText, setIndexActiveInfoText] = useState(1)
    
    useEffect(() => {  
        setLocalizacao(infoCarrossel(empreendimento, 1))
        setInfraestrutura(infoCarrossel(empreendimento, 2))
        setBeneficios(infoCarrossel(empreendimento, 3))
        pagePassInfoText(indexActiveInfoText)
    }, [empreendimento]); 

    //Passar automatico
    /*useEffect(() => {

        const interval = setInterval(() => {
            setIndexActiveInfoText((prevIndex) => {
                const nextIndex = prevIndex + 1 > 3 ? 1 : prevIndex + 1;
                pagePassInfoText(nextIndex);
                return nextIndex;
            });
        }, 5000);
        return () => clearInterval(interval);
    }, []); */
    

    return(
       
        <div className='content-info_empreendimento'>  

            <div className='btnRight' onClick={(e) => pagePass('P')}>
                <img src={btnRightImg} />
            </div>                 

            <div className='content-text-info'>
                <div className='text-empreendimento'>
                    <strong><h4>Localização Estratégica</h4></strong><br/>
                    <TextInfoEmpreendimento html={localizacao}></TextInfoEmpreendimento>
                </div>
                <div className='text-empreendimento'>
                    <strong><h4>Infraestrutura</h4></strong><br/>
                    <TextInfoEmpreendimento html={infraestrutura}></TextInfoEmpreendimento>
                </div>
                <div className='text-empreendimento'>
                    <strong><h4>Mais Benefícios</h4></strong><br/>
                    <TextInfoEmpreendimento html={beneficios}></TextInfoEmpreendimento>
                </div>

                <div className='content-buttons'>
                    <button className='btn-page-info' onClick={(e) => pagePassInfoText(1)} ></button>
                    <button className='btn-page-info' onClick={(e) => pagePassInfoText(2)} ></button>
                    <button className='btn-page-info' onClick={(e) => pagePassInfoText(3)} ></button>
                </div>
            </div>              

            <div className='btnLeft' onClick={(e) => pagePass('N')}>
                <img src={btnLeftImg} />
            </div>
             
              
        </div>       

    )    

}
 

export default CarrosselInfo;