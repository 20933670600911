import React, {useState, useEffect} from 'react'

//Styles
import './style.css'

//Videos
import video from './video/UEI2.mp4'

function Inicial(){ 
   
    return(
        <>
            <video 
                className='video-inicial'    
                src={video}                            
                autoPlay
                muted
                loop
                style={{width:'100%', height:'auto'}}
            />      
            
        </>
    )
} 

export default Inicial;