import $ from 'jquery'

//Styles
import './style.css'

//Imagens
import logomarca from './img/logomarca.png'

//Components
import Inicial from '../Inicial';

// Função para rolar a página suavemente até uma determinada seção
const scrollToSection = (sectionId) => {
    const windowHeight = $(window).height(); // Altura da janela do navegador
    const windowMiddle = windowHeight / 5; // Meio da altura da janela
    const sectionOffsetTop = $(sectionId).offset().top; // Posição superior da seção
    const scrollTo = sectionOffsetTop - windowMiddle + 50; // Rolar para colocar o topo da seção no meio da janela
    $('html, body').animate({
        scrollTop: scrollTo
    }, 1000); // Ajuste a duração conforme necessário
}

function Header(){ 

    function handleMenuClick(event, sectionId) {
        event.preventDefault(); // Impede o comportamento padrão do link
        scrollToSection(sectionId);
    }

    return(
        <header>    
            <div className='content-header'>
                <nav className='menu'>
                    <a href='#'><img className='logo-header' src={logomarca} /></a>
                    <a className='link-header' onClick={(event) => handleMenuClick(event, '#sobre')}>Sobre</a>                    {/*<a className='link-header' onClick={(event) => handleMenuClick(event, '#parcerias')}>Parcerias</a>*/}
                    
                    <a className='link-header' onClick={(event) => handleMenuClick(event, '#empreendimentos')}>Empreendimentos</a>
                    <a className='link-header' onClick={(event) => handleMenuClick(event, '#contato')}>Contato</a>
                </nav>
                <nav className='menu-2'>
                    <div className='content-dropdown-menu'>
                        <div className='btn-dropdown'>
                            <svg viewBox="0 0 32 32">
                                <path className='btn-rows btn-rown-top' d='M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22'></path>
                                <path className='btn-rows' d='M7 16 27 16'></path>
                            </svg>
                        </div>
                        <div className='dropdown-menu'>
                            <a className='link-header' onClick={(event) => handleMenuClick(event, '#sobre')}>Sobre</a>
                            {/*<a className='link-header' onClick={(event) => handleMenuClick(event, '#parcerias')}>Parcerias</a>*/}
                            <a className='link-header' onClick={(event) => handleMenuClick(event, '#empreendimentos')}>Empreendimentos</a>
                            <a className='link-header' onClick={(event) => handleMenuClick(event, '#contato')}>Contato</a>
                        </div>
                    </div>
                    <div className='content-logo'>
                        <a href='#'><img className='logo-header' src={logomarca}/></a>
                    </div> 
                </nav>
            </div>    
            
            <hr/>  
            <div className='video-text'>
                <h3>Os melhores investimentos começam com um pedaço de terra pronto para transformar em seu Sonho!</h3>
            </div>            
            <Inicial></Inicial>

        </header>
    )
    
}


export default Header;