import {useState, useEffect} from 'react'
import emailjs from '@emailjs/browser'
import $ from 'jquery'


//Styles
import './style/App.css';
import './style/colors.css';
import './style/wideScreen.css';

//Components
import Header from './components/Header';
import Footer from './components/Footer';
import ButtonTop from './components/ButtonTop';
import Carrossel from './components/Carrossel';
import CarrosselInfo from './components/CarrosselInfo';
import Imagem360 from './components/Imagem360';



//Imagens
import bosque from './img/bosque.png'
import paraguacu from './img/paraguacu.png'
import integridade from './img/integridade.png'
import objetivo from './img/objetivo.png'
import atendimento from './img/atendimento.png'

//Video
import videoBosqueRecanto from './videos/BosqueRecanto.mp4'
import loteamentoParaguacu from './videos/LoteamentoParaguacu.mp4'


//----------------------------------------------

function App() {

  // Constantes do UseState
  const [nome, setNome] = useState('')
  const [sobrenome, setSobrenome] = useState('')
  const [telefone, setTelefone] = useState('')
  const [email, setEmail] = useState('')
  const [mensagem, setMensagem] = useState('')
  const [error, setError] = useState('')
  const [empreendimento, setEmpreendimento] = useState('bosque')
  const [videoEmpreendimento, setVideoEmpreendimento] = useState(null)
  
  const sendEmail = (e) => {
    e.preventDefault();    
      if(nome === '' || sobrenome === '' || telefone === '' || email === ''){
        alert("Preencha todos os campos");
        return;
      }
      
      const templateParams = {
        from_name: nome,
        surname: sobrenome,
        email: email,
        phone: telefone
      }

      emailjs.send("service_f3ejlxq", "template_k060plr", templateParams, "KBt3o4VNO9MZwNiAi")
      .then((response)=>{
        
        setNome('')
        setSobrenome('')
        setTelefone('')
        setEmail('')

        setMensagem("Email enviado com sucesso. Entraremos em contato em breve!")
        setTimeout(() =>{
          setMensagem('')
        }, 4000)       

      }, (err) =>{
        console.log("ERRO: ", err)
        setError("Ocorreu um erro. Por favor, verifique o e-mail inserido.")
        setTimeout(() =>{
          setError('')
        }, 4000)

      })
  }

  const selectEmpreendimento = (empe) => {
    setEmpreendimento(empe);

    if (empe === 'bosque') {
        $('#bosque').addClass('ativoEmpe');
        $('#paraguacu').removeClass('ativoEmpe');
        
        window.history.pushState(null, null, '/bosque-recanto');
    } else {
        $('#paraguacu').addClass('ativoEmpe');
        $('#bosque').removeClass('ativoEmpe');
        
        window.history.pushState(null, null, '/novo-paraguacu');
    }
  };

  const videoEmpe = (empe) => {
    if(empe === 'bosque'){
      setVideoEmpreendimento(videoBosqueRecanto)
    }else{
      setVideoEmpreendimento(loteamentoParaguacu)
    }
  }

  useEffect(() => {
    const path = window.location.pathname.replace('/', '');
    if (path === 'bosque-recanto' || path === 'novo-paraguacu') {    
      if (path === 'bosque-recanto') {
        setEmpreendimento('bosque');
        $('#paraguacu').removeClass('ativoEmpe');
        $('#bosque').addClass('ativoEmpe');        
      } else {
        setEmpreendimento('paraguacu');
        $('#bosque').removeClass('ativoEmpe');
        $('#paraguacu').addClass('ativoEmpe');        
      } 
      
      
      const scrollToSection = () => {
        const section = document.getElementById('empreendimentos');
        if (section) {
          const targetPosition = section.offsetTop;
          window.scrollTo({
            top: targetPosition,
            behavior: 'instant' // 'smooth' para animação, 'instant' para ir direto
          });
        }
      };
      
      // Chama a função de scroll imediatamente após ajustar o estado
      scrollToSection();
    }    

  }, []);

  useEffect(() => {
    videoEmpe(empreendimento);
  }, [empreendimento]);
 
  

  return (
    <>
      <ButtonTop></ButtonTop>
      
      <Header></Header>      

      <section id='empreendimentos'>
        <div className='content-section'>

          <div className='header-empreendimentos'>
            <h2>Nossos Empreendimentos</h2>

            <div className='content-empe'>
                <div id='bosque' className='ativoEmpe' onClick={() => selectEmpreendimento('bosque')}>
                  <img src={bosque} alt='logo empreendimento Bosque Recanto'></img> 
                </div>
                <div id='paraguacu' onClick={() => selectEmpreendimento('paraguacu')}>
                  <img src={paraguacu} alt='logo empreendimento Novo Paraguaçu'></img> 
                </div>                             
            </div>            
          </div>

          <div className='content-empreendimentos'>
            
            <Carrossel empreendimento={empreendimento}></Carrossel>
            <CarrosselInfo empreendimento={empreendimento}></CarrosselInfo>

            <div className='content-info-empreendimentos'>
              <Imagem360 empreendimento={empreendimento}></Imagem360>            
              <video className='video-empreendimento' src={videoEmpreendimento} autoPlay muted loop></video>
            </div>         

          </div>                      

          
        </div>            
      </section>

      <section id='sobre'>
        <div className='content-section'>
          <div className='cards-header'>
            Mantendo nosso compromisso com a qualidade, preservamos nossos princípios, sempre focados no principal objetivo: <i>REALIZAR SONHOS</i>
          </div>
          
          <div className='content-cards'>            
            <div className='cards'>              
              <h4>Objetivo</h4>      
              <img src={objetivo}></img>           
              <p>Nosso objetivo é transformar o sonho da casa própria em realidade. Com 5 anos de experiência em Mar Grande-BA, oferecemos empreendimentos regularizados que garantem segurança e confiança em cada compra.</p>
            </div>
            <div className='cards'>              
              <h4>Integridade e Transparência</h4>  
              <img src={integridade}></img>            
              <p>Prezamos pela integridade e transparência. Todos os nossos imóveis são regularizados, assegurando uma compra segura e sem surpresas, com clareza em cada etapa do processo.</p>
            </div>
            <div className='cards'>
              <h4>Excelência no Atendimento</h4>
              <img src={atendimento}></img>
              <p>Nossa equipe está sempre pronta para oferecer um atendimento de alta qualidade. Garantimos que nossos clientes se sintam confortáveis e realizados com suas escolhas, do início ao fim.</p>
            </div>            
          </div>

          <div className='cards-footer'>
            <h3>Transforme Seus Sonhos em Realidade <br/>Garanta Sua Unidade</h3>
          </div>

        </div>       
      </section>

      <section id='contato'>
        <div className='content-section'>
          <h2>Entre em Contato Conosco</h2>   
          <div className='content-form'>
            <form onSubmit={sendEmail}>
              <input 
                id='email'
                className='inputLabel' 
                type='email' 
                onChange={(e) => setEmail(e.target.value)} 
                value={email}  
                placeholder='E-Mail'    
                required           
              />

              <div> 
                <input 
                  id='nome'
                  className='inputLabel' 
                  type='text' 
                  onChange={(e) => setNome(e.target.value)} 
                  value={nome} 
                  placeholder='Nome'
                  required
                />

                <input 
                  id='sobrenome'
                  className='inputLabel' 
                  type='text'                 
                  onChange={(e) => setSobrenome(e.target.value)} 
                  value={sobrenome} 
                  placeholder='Sobrenome'  
                  required              
                />
              </div>            
                              
              <input    
                id='telefone'           
                className='inputLabel' 
                type='tel' 
                onChange={(e) => setTelefone(e.target.value)} 
                value={telefone} 
                placeholder='Telefone para contato'
                required
              />
                                
              <button className='btn'>Enviar</button>

              <p className='mensagem'>{mensagem}</p>
              <p className='erro'>{error}</p>
            </form>

          </div>  
            
        </div>  
        <hr/>     
      </section>

      <Footer></Footer>    

    </>
  );
}

export default App;