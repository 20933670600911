import  $ from 'jquery'

//Styles
import './style.css'

//Imagens
import btnTopImg from './img/btnTop.png'

function ButtonTop(){ 

    const scrollTopButton = () => {
        $('html, body').animate({
            scrollTop: 0
        }, 800, 'swing')
    }

    return(
        <div id='btnTop' onClick={scrollTopButton}>
            <img src={btnTopImg} />
        </div>
    )    
}


export default ButtonTop;


